import React, { useState, useEffect } from 'react';
import { Grid, Drawer, Button, useMediaQuery, useTheme, Typography, Box } from '@mui/material';
import plusIcon from '../assets/icon-plus.svg';
import seatedIcon from '../assets/icon-seated.svg';
import locationIcon from '../assets/icon-location.svg';
import guestIcon from '../assets/icon-guests.svg';
import arrowRight from "../assets/arrow-right.svg";
import logo from '../assets/logo.png';
import TentSelectionExtras from './TentSelectionExtras';
import TentSelectionCard from './TentSelectionCard';
import Footer from './Footer';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

function TentSelection() {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { formData, selectedTypes: initialSelectedTypes,
        types: initialTypes, products: initialProducts,
        additionalItems: initialAdditionalItems,
        selectedCardId: initialSelectedCardId,
        selectedProduct: initialSelectedProduct,

    } = location.state || {};

    const [selectedTypes, setSelectedTypes] = useState(initialSelectedTypes || []);
    const [types, setTypes] = useState(initialTypes || []);
    const [products, setProducts] = useState(initialProducts || {});
    const [shortlistedItems, setShortlistedItems] = useState([]);
    const [additionalItems, setAdditionalItems] = useState(initialAdditionalItems || []);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedCardId, setSelectedCardId] = useState(initialSelectedCardId || null);
    const [selectedProduct, setSelectedProduct] = useState(initialSelectedProduct || null);
    const [itemQuantities, setItemQuantities] = useState({});

    const fetchTypes = async () => {
        try {
            const response = await axios.get('https://eventquote-api.techenablers.info/api/types');
            setTypes(response.data);
        } catch (error) {
            console.error('Error fetching types:', error);
        }
    };

    const homePage = () => {
        navigate("/")
    }

    // Fetch products for a specific type
    const fetchProducts = async (typeId) => {
        try {
            const response = await axios.get(`https://eventquote-api.techenablers.info/api/types/${typeId}/products`);
            setProducts(prevProducts => {
                const newProducts = {
                    ...prevProducts,
                    [typeId]: response.data,
                };
                updateAdditionalItems(newProducts);
                setSelectedCardId(response.data?.products[0]?.id);
                setSelectedProduct(response.data.products);
                return newProducts;
            });
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    // Update additional items based on products
    const updateAdditionalItems = (products) => {
        const inventories = Object.values(products || {}).flatMap(category => category.products || []);
        const itemMap = new Map();

        inventories.forEach(product => {
            const productInventories = product.inventories || [];
            productInventories.forEach(inventory => {
                itemMap.set(inventory.name, {
                    id: inventory.id,
                    name: inventory.name,
                    checked: inventory.default,
                    shape: inventory.shape,
                    price: inventory.price,
                    type: inventory.type,
                    info_text: inventory.info_text,
                    // qty: inventory.qty,
                    qty: inventory.default ? 1 : 0,
                });
            });
        });

        const uniqueItems = Array.from(itemMap.values());
        setAdditionalItems(uniqueItems);
        setProducts(products);
    };

    // Handle checkbox changes for selecting types
    const handleCheckboxChange = (id) => {
        setSelectedTypes(prevSelected => {
            if (prevSelected.includes(id)) {
                const updatedSelectedTypes = prevSelected.filter(typeId => typeId !== id);
                setProducts(prevProducts => {
                    const updatedProducts = { ...prevProducts };
                    delete updatedProducts[id];
                    updateAdditionalItems(updatedProducts);
                    return updatedProducts;
                });
                return updatedSelectedTypes;
            } else {
                fetchProducts(id);
                return [...prevSelected, id];
            }
        });
    };

    const handleAdditionalItemChange = (itemName, checked) => {
        setProducts(prevProducts => {
            const updatedProducts = { ...prevProducts };

            // Iterate each product type
            Object.keys(updatedProducts).forEach(typeId => {
                updatedProducts[typeId] = {
                    ...updatedProducts[typeId],
                    products: updatedProducts[typeId].products.map(product => ({
                        ...product,
                        inventories: product.inventories.map(inventory => {
                            if (inventory.name === itemName) {
                                return {
                                    ...inventory,
                                    default: checked,
                                    qty: checked ? 1 : 0
                                    // qty: inventory.type === "Single" ? 1 : itemQuantities[itemName] || 0 // Set qty
                                };
                            }
                            return inventory;
                        })
                    }))
                };
            });

            return updatedProducts;
        });

        setAdditionalItems(prevItems =>
            prevItems.map(item =>
                item.name === itemName ? { ...item, checked: checked } : item
            )
        );
    };



    const handleQuantityChange = (itemName, value) => {
        const newValue = value === '' ? 0 : parseInt(value, 10);

        setItemQuantities(prev => ({
            ...prev,
            [itemName]: newValue
        }));
        setAdditionalItems(prevItems =>
            prevItems.map(item =>
                item.name === itemName
                    ? { ...item, qty: newValue, checked: newValue > 0 }
                    : item
            )
        );
        setProducts(prevProducts => {
            const updatedProducts = { ...prevProducts };

            Object.keys(updatedProducts).forEach(typeId => {
                updatedProducts[typeId] = {
                    ...updatedProducts[typeId],
                    products: updatedProducts[typeId].products.map(product => ({
                        ...product,
                        inventories: product.inventories.map(inventory => {
                            if (inventory.name === itemName) {
                                return {
                                    ...inventory,
                                    qty: inventory.type === "Single" ? 1 : newValue,
                                    default: newValue > 0 // Set default to false if qty is 0
                                };
                            }
                            return inventory;
                        })
                    }))
                };
            });

            return updatedProducts;
        });
    };


    useEffect(() => {
        console.log('Products after update:', products);
    }, [products]);

    const handleShapeChange = (itemName, toggleToNextShape) => {
        setProducts(prevProducts => {
            const updatedProducts = { ...prevProducts };

            Object.keys(updatedProducts).forEach(typeId => {
                updatedProducts[typeId] = {
                    ...updatedProducts[typeId],
                    products: updatedProducts[typeId].products.map(product => ({
                        ...product,
                        inventories: product.inventories.map(inventory => {
                            if (inventory.name === itemName) {
                                // Determine the next shape based on the current shape
                                const newShape =
                                    inventory.shape === 'Rect' || inventory.shape === 'Round'
                                        ? toggleToNextShape ? 'Round' : 'Rect'
                                        : toggleToNextShape ? 'Chairs' : 'Benches';

                                return {
                                    ...inventory,
                                    shape: newShape
                                };
                            }
                            return inventory;
                        })
                    }))
                };
            });

            return updatedProducts;
        });

        setAdditionalItems(prevItems =>
            prevItems.map(item =>
                item.name === itemName
                    ? {
                        ...item,
                        shape:
                            item.shape === 'Rect' || item.shape === 'Round'
                                ? toggleToNextShape ? 'Round' : 'Rect'
                                : toggleToNextShape ? 'Chairs' : 'Benches'
                    }
                    : item
            )
        );
    };

    const handleShortlist = (product) => {
        setShortlistedItems(prevShortlisted => {
            const existingProductIndex = prevShortlisted.findIndex(item => item.id === product.id);
            const updatedProduct = {
                ...product,
                size: product.size,
                inventories: product.inventories.filter(inventory => inventory.qty > 0)
            };
            if (updatedProduct.inventories.length === 0) return prevShortlisted;
            if (existingProductIndex > -1) {
                const updatedShortlist = [...prevShortlisted];
                updatedShortlist[existingProductIndex] = updatedProduct;
                return updatedShortlist;
            }

            return [...prevShortlisted, updatedProduct];
        });
    };

    // Pass the shortlisted items to the order page with the correct qty values
    const proceed = () => {
        navigate("/quote-details", {
            state: {
                selectedTypes: selectedTypes,
                types: types,
                products: products,
                dataFields: formData,
                shortlistedItems: transformShortlistedItems()
            }
        });
    };

    const transformShortlistedItems = () => {
        return shortlistedItems.map(item => ({
            id: item.id,
            name: item.name,
            size: item.size,
            totalPrice: item.totalPrice,
            inventories: item.inventories.map(inv => ({
                ...inv,
                qty: inv.type === "Single" ? 1 : itemQuantities[inv.name] || inv.qty
                // qty: itemQuantities[inv.name]?.quantity || 0,
                // shape: itemQuantities[inv.name]?.shape || 'rect'
            }))
        }));
    };


    const handleDelete = (id) => {
        setShortlistedItems(prevShortlisted =>
            prevShortlisted.filter(item => item.id !== id)
        );
    };

    useEffect(() => {
        fetchTypes();
    }, []);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    useEffect(() => {
        if (selectedProduct?.length > 0) {
            const inventories = selectedProduct[0]?.inventories || [];
            const updatedItems = inventories.map(inventory => ({
                id: inventory.id,
                name: inventory.name,
                checked: inventory.default,
                shape: inventory.shape,
                price: inventory.price,
                type: inventory.type,
                info_text: inventory.info_text,
                qty: inventory.qty,
            }));
            setAdditionalItems(updatedItems);
        }
    }, [selectedProduct]);



    return (
        <div className='container-side'>
            <div className='header'>
                <Grid container spacing={4}>
                    {!isSmallScreen &&
                        <Grid item xs={4}>
                            <img src={logo} alt="Logo" width="70%" className='ml-40 cursor-pointer' onClick={homePage} />
                        </Grid>
                    }
                    <Grid item xs={isSmallScreen ? 12 : 8}>
                        <div className='headerNav'>
                            <div className='ps-20'>
                                <h2 className='fs-35'>Tent Selection & Extras</h2>
                                <div className='tentSelectionIcons'>
                                    <div className='iconText'>
                                        <img src={locationIcon} alt="location" />
                                        <Typography className='fs-16'><span className='hidden'>Location:</span> {formData?.venuePost ? formData?.venuePost : 'Not Provided'}</Typography>
                                    </div>
                                    <div className='iconText'>
                                        <img src={guestIcon} alt="guest" />
                                        <Typography className='fs-16'><span className='hidden'>guests:</span> {formData?.totalGuests ? formData?.totalGuests : 0}</Typography>
                                    </div>
                                    <div className='iconText'>
                                        <img src={seatedIcon} alt="seated" />
                                        <Typography className='fs-16'><span className='hidden'>seated:</span> {formData?.seats ? formData?.seats : 0}</Typography>
                                    </div>
                                </div>
                            </div>
                            <div className="stepper-container">
                                <ol className="stepper">
                                    <li className="step">1</li>
                                    <li className="step active">2</li>
                                    <li className="step">3</li>
                                    <li className="step">4</li>
                                </ol>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className='body'>
                <Grid container spacing={4}>
                    <Grid item xs={isSmallScreen ? 12 : 4}>
                        {!isSmallScreen ? (
                            <TentSelectionExtras
                                isSmallScreen={isSmallScreen}
                                types={types}
                                onCheckboxChange={handleCheckboxChange}
                                selectedTypes={selectedTypes}
                                products={products}
                                onAdditionalItemChange={handleAdditionalItemChange}
                                additionalItems={additionalItems}
                                shortlistedItems={shortlistedItems}
                                onDelete={handleDelete}
                                onProceed={proceed}
                                selectedCardId={selectedCardId}
                                selectedProduct={selectedProduct}
                                itemQuantities={itemQuantities}
                                handleQuantityChange={handleQuantityChange}
                                handleShapeChange={handleShapeChange}
                            />
                        ) : (
                            <Drawer
                                anchor="left"
                                open={drawerOpen}
                                onClose={handleDrawerToggle}
                                className=''
                                classes={{ paper: 'drawerBackground' }}
                            >
                                <TentSelectionExtras
                                    isSmallScreen={isSmallScreen}
                                    handleDrawerToggle={handleDrawerToggle}
                                    types={types}
                                    onCheckboxChange={handleCheckboxChange}
                                    selectedTypes={selectedTypes}
                                    products={products}
                                    onAdditionalItemChange={handleAdditionalItemChange}
                                    additionalItems={additionalItems}
                                    shortlistedItems={shortlistedItems}
                                    onDelete={handleDelete}
                                    onProceed={proceed}
                                    selectedCardId={selectedCardId}
                                    selectedProduct={selectedProduct}
                                    itemQuantities={itemQuantities}
                                    handleQuantityChange={handleQuantityChange}
                                    handleShapeChange={handleShapeChange}
                                />
                            </Drawer>
                        )}
                    </Grid>

                    <Grid item xs={isSmallScreen ? 12 : 8}>
                        {selectedTypes.length > 0 ?
                            (
                                <TentSelectionCard
                                    products={products}
                                    onShortlist={handleShortlist}
                                    additionalItems={additionalItems}
                                    setAdditionalItems={setAdditionalItems}
                                    formData={formData}
                                    selectedCardId={selectedCardId}
                                    setSelectedCardId={setSelectedCardId}
                                    selectedProduct={selectedProduct}
                                    setSelectedProduct={setSelectedProduct}
                                    itemQuantities={itemQuantities}
                                    setItemQuantities={setItemQuantities}
                                />
                            ) : (
                                <div variant="h6">Please select a tent type to view products.</div>
                            )}
                    </Grid>

                    {isSmallScreen && (
                        <>
                            <Button
                                variant="contained"
                                className="extras-button"
                                startIcon={<img src={plusIcon} alt="plus icon" />}
                                onClick={handleDrawerToggle}
                            >
                                Extras
                            </Button>
                            <Button
                                variant="contained"
                                className="proceed-button"
                                endIcon={<img src={arrowRight} alt="proceed" />}
                                onClick={proceed}
                            >
                                Proceed
                            </Button>
                        </>
                    )}
                </Grid>

                <Box className="contactInfoFlex contactInfoTent">
                    <Footer />
                </Box>

            </div>
        </div >
    )
}

export default TentSelection;
