import React from 'react';
import logo from '../assets/logo.png';
import { useNavigate } from 'react-router-dom';

function Hedaer() {

    const navigate = useNavigate();

    const homePage = () => {
        navigate("/")
    }
    return (
        <div className='headerNav'>
            <div onClick={homePage}>
                <img src={logo} alt="Logo" width="50%" className='cursor-pointer' />
            </div>
            <div className="stepper-container">
                <ol className="stepper">
                    <li className="step active">1</li>
                    <li className="step">2</li>
                    <li className="step">3</li>
                    <li className="step">4</li>
                </ol>
            </div>
        </div>
    )
}

export default Hedaer