import React from 'react';
import logo from "../assets/logo.png";
import close from "../assets/close-side.svg";
import { Checkbox, Button, TextField, Typography, Switch, Box, FormHelperText } from '@mui/material';
import deleteTent from '../assets/delete-tent.svg';
import arrowRight from "../assets/arrow-right.svg";
import { useNavigate } from "react-router-dom";

function TentSelectionExtras(props) {
    const navigate = useNavigate();
    const {
        isSmallScreen,
        handleDrawerToggle,
        types,
        onCheckboxChange,
        selectedTypes,
        additionalItems,
        onAdditionalItemChange,
        shortlistedItems,
        onDelete,
        onProceed,
        itemQuantities,
        handleQuantityChange,
        handleShapeChange
    } = props;

    const homePage = () => {
        navigate("/")
    }

    return (
        <div>
            {isSmallScreen &&
                <div className='header p-55'>
                    <div className='headerNav sm-flex'>
                        <img src={logo} alt="Logo" width="70%" className='cursor-pointer' onClick={homePage} />
                        <img src={close} alt="close" onClick={handleDrawerToggle} width='20px' className='cursor-pointer' />
                    </div>
                </div>
            }
            <div className='body'>
                <h1 className={`extrasHeader ${isSmallScreen ? 'paddingTopSmallScreen' : 'paddingTopLargeScreen'}`}>
                    Quote Builder
                </h1>
                {!isSmallScreen &&
                    <>
                        <h3 className='filterTents'>
                            Your Selection
                        </h3>
                        <div className='filterTentList'>
                            {shortlistedItems.length > 0 ? (
                                <>
                                    {shortlistedItems.map((item, index) => (
                                        <div key={index}>
                                            <div className="flexSpaceBetween">
                                                <h3>{item.description}</h3>
                                                <img src={deleteTent} alt="delete" className="deleteTent" onClick={() => onDelete(item.id)} />
                                            </div>
                                            <div className="fs-16 fw-7">{item?.size}</div>
                                            <div className="fs-16 pt-4">Options:</div>
                                            <ul className="fs-16">
                                                {item.inventories.map(inventory => (
                                                    <li key={inventory.id}>
                                                        {inventory.type === "Multiple" ? `${inventory.name}: ${inventory.qty}` : `${inventory.name}`}
                                                    </li>
                                                ))}
                                            </ul>
                                            <div className="fs-35 fw-7">£{item.totalPrice.toFixed(2)}</div>
                                            <Typography className="font_opacity">Includes VAT & Insurance</Typography>
                                        </div>
                                    ))}
                                    <Button
                                        variant="contained"
                                        className="white-button"
                                        onClick={onProceed}
                                        endIcon={<img src={arrowRight} alt="icon" />}
                                    >
                                        Let's start talking
                                    </Button>
                                </>
                            ) : (
                                <div>You currently have no items added…</div>
                            )}
                        </div>
                    </>
                }
                <h3 className='filterTents'>
                    Filter Tents
                </h3>
                <div className='filterTentList'>
                    {types?.length > 0 ? (
                        types.map(type => (
                            <div className='flexContainer' key={type.id}>
                                <div className="cursor-context">Include {type.name}</div>
                                <Checkbox
                                    className="custom-checkbox"
                                    checked={selectedTypes.includes(type.id)}
                                    onChange={() => onCheckboxChange(type.id)}
                                />
                            </div>
                        ))
                    ) : (
                        <div>Loading...</div>
                    )}
                </div>
                <h3 className='filterTents'>
                    Add & Remove Items
                </h3>
                <div className='filterTentList'>
                    {additionalItems.length > 0 ? (
                        <>
                            {additionalItems.map(item => (
                                <div className='flexContainer' key={item.name}>
                                    {/* <div className="cursor-context">{item.name}</div> */}
                                    {item?.shape ? (
                                        <Box>
                                            <p className='fs-16'>{item?.info_text}</p>
                                            <Box className='flexSpaceBetween'>
                                                <Box display="flex" alignItems="center">
                                                    {(item.shape === 'Rect' || item.shape === 'Round') && (
                                                        <>
                                                            <Typography className='fs-16'>Rectangular</Typography>
                                                            <Switch
                                                                className="custom-switch"
                                                                checked={item.shape === 'Round'}
                                                                onChange={() => handleShapeChange(item.name, item.shape !== 'Round')}
                                                            />
                                                            <Typography className='fs-16'>Round</Typography>
                                                        </>
                                                    )}

                                                    {(item.shape === 'Benches' || item.shape === 'Chairs') && (
                                                        <>
                                                            <Typography className='fs-16'>Benches</Typography>
                                                            <Switch
                                                                className="custom-switch"
                                                                checked={item.shape === 'Chairs'}
                                                                onChange={() => handleShapeChange(item.name, item.shape !== 'Chairs')}
                                                            />
                                                            <Typography className='fs-16'>Chairs</Typography>
                                                        </>
                                                    )}

                                                </Box>

                                                <div className="cursor-context">{item.name}</div>
                                                <Box display="flex" flexDirection="column" alignItems="center">
                                                    <TextField
                                                        id="shapeQty"
                                                        variant="filled"
                                                        type="number"
                                                        // value={itemQuantities[item.name] || ''}
                                                        value={item.qty === 0 ? '' : item.qty}
                                                        onChange={(e) => handleQuantityChange(item.name, e.target.value)}
                                                        className="qty-field"
                                                        inputProps={{ min: 0 }}
                                                        style={{ width: "60px" }}
                                                        sx={{
                                                            'input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button': {
                                                                WebkitAppearance: 'none',
                                                                margin: 0,
                                                            },
                                                            'input[type=number]': {
                                                                MozAppearance: 'textfield', // Hide arrows in Firefox
                                                            },
                                                        }}
                                                    />
                                                    <FormHelperText className='fs-12 c-white'>Qty</FormHelperText>
                                                </Box>
                                            </Box>
                                        </Box>
                                    ) : (
                                        item?.type === "Single" ? (

                                            <>
                                                <div className="cursor-context">{item.name}</div>
                                                <Checkbox
                                                    className="custom-checkbox"
                                                    checked={item.checked}
                                                    onChange={() => onAdditionalItemChange(item.name, !item.checked)}
                                                />
                                            </>
                                        ) : (
                                            <div className='flexSpaceBetween'>
                                                <div className="cursor-context">{item.name}</div>
                                                <Box display="flex" flexDirection="column" alignItems="center">
                                                    <TextField
                                                        id="multiple"
                                                        variant="filled"
                                                        type="number"
                                                        // value={itemQuantities[item.name] || ''}
                                                        value={item.qty === 0 ? '' : item.qty}
                                                        onChange={(e) => handleQuantityChange(item.name, e.target.value)}
                                                        className="qty-field"
                                                        inputProps={{ min: 0 }}
                                                        style={{ width: "60px" }}
                                                        sx={{
                                                            'input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button': {
                                                                WebkitAppearance: 'none',
                                                                margin: 0,
                                                            },
                                                            'input[type=number]': {
                                                                MozAppearance: 'textfield', // Hide arrows in Firefox
                                                            },
                                                        }}
                                                    />
                                                    <FormHelperText className='fs-12 c-white'>Qty</FormHelperText>
                                                </Box>
                                            </div>
                                        )
                                    )}
                                </div>
                            ))}
                        </>
                    ) : (
                        <div>No items available</div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default TentSelectionExtras;
