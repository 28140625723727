import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import arrowRight from "../assets/white-arrow-right.svg";
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

function ThankYou() {
    const navigate = useNavigate();

    const backToWebsite = () => {
        navigate("/");
    }
    return (
        <div className='container thankyou'>
            <div className='header thankyouHeader'>
                <div className="stepper-container">
                    <ol className="stepper">
                        <li className="step">1</li>
                        <li className="step">2</li>
                        <li className="step">3</li>
                        <li className="step active">4</li>
                    </ol>
                </div>
            </div>
            <div className='body text-center'>
                <h2 className='fs-35'>Thank you</h2>
                <p className='mainBodyText'>
                    A member of the team will be in touch soon. <br />
                    A copy of your quote has been sent to your email address.
                </p>

                <Button className='whiteButton' endIcon={<img src={arrowRight} alt="website" />} onClick={backToWebsite}>
                    Back to Website
                </Button>

                <Button type="submit" variant='contained' className='main-button' endIcon={<img src={arrowRight} alt="website" />}>
                    Start another quote
                </Button>
            </div>

            <div className="footer">
                <Typography className='fs-60 fw-7'>Let's get started</Typography>
                <Box className="contactInfo">
                    <Footer />
                </Box>
            </div>
        </div>
    )
}

export default ThankYou