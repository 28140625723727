import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <>
            <Link to="/contact/" className='fs-16'>Contact</Link>
            <Link to="mailto:usinfo@eventsundercanvas.co.uk" className='fs-16'>Usinfo@eventsundercanvas.co.uk</Link>
            <Link to="tel:01206 298074" className='fs-16'>01206 298074</Link>
        </>
    )
}

export default Footer